.fixedlogo{
    width:40px;
    height:40px;
    border-radius:50%;
    position:fixed;
    top:0;
    left:0;
    z-index:9999999999;
    margin-left:15px;
    margin-top:5px;
    cursor: pointer;
}

.floating_history_button_left{
    position: fixed;
    top: 50%;
    left: 0;
    height: 60px;
    width: max-content;
    z-index: 99999;
    background-color: white;
    outline: none;
    border: 1px solid #ccc;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
}

.floating_history_button_right{
    position: fixed;
    top: 50%;
    right: 0%;
    height: 60px;
    width: max-content;
    z-index: 99999;
    background-color: white;
    outline: none;
    border: 1px solid #ccc;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
}

.wbBody{
    font-size: 16px;
}

@media (max-width:800px) {
    .fixedlogo{
        display: none;
    }
}